import React, { Fragment } from 'react';
import styled from 'styled-components';

import ArticleCardsBigOne from '../components/ArticleCardsBigOne';
import Container from '../components/Container';
import FadeEntry from '../components/FadeEntry';
import MetaData from '../components/MetaData';
import _Page from '../components/Page';
import Title from '../components/Title';

const News = ({ pageContext: { articles, currentPage, numPages } }) => {

	if(!articles || !articles.length) return null;

	return(
		<Page>
			<MetaData
				title={'News'}
			/>

			<Container>
				<FadeEntry
					className={'page-title'}
				>
					<Title
						as={'h1'}
					>News</Title>
				</FadeEntry>

				<Inner>
					<ArticleCardsBigOne
						posts={articles}
					/>
				</Inner>
			</Container>
		</Page>
	);
}

const Inner = styled.div``;

const Page = styled(_Page)`
	.page-title {
		>${Title} {
			margin-bottom: 65px;
			text-align: center;
			font-size: ${({ theme }) => theme.fontSize.titleLg};
			
			@media (max-width: 767px) {
				margin-bottom: 40px;
			}
		}
	}
`;

export default News;