import React, { useState, useEffect } from 'react';
import axios from 'axios';

export const useTweets = (count = 2) => {
	let [tweets, setTweets] = useState(null);

	useEffect(() => {
		async function getTweets() {
			try {
				let { data } = await axios({
					method: 'GET',
					url: 'https://us-central1-wri-group.cloudfunctions.net/getLatestTweets',
					headers: {
						'Content-Type': 'application/json'
					}
				});

				setTweets(data);
			} catch(error) {
				console.error(error);
			}
		}

		getTweets();
	}, []);

	if(tweets && tweets.length) {
		tweets = tweets.slice(0, count);
	}

	return tweets;
}