import React, { useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Link, useStaticQuery, graphql } from 'gatsby';

import { useTweets } from '../utils/hooks';

import ArticleCards, { ArticleCard, shortenExcerpt } from './ArticleCards';
import Button from './Button';
import FadeEntry from './FadeEntry';
import ReadMore from './ReadMore';
import Title from './Title';
import Tweets from './Tweets';

const ArticleCardsBigOne = ({ posts }) => {

	let [page, setPage] = useState(1);

	if(!posts || !posts.length) return null;

	let { wordpressAcfOptions: {
		options: {
			placeholder_image
		}
	} } = useStaticQuery(graphql`
		{
			wordpressAcfOptions {
				options {
				  placeholder_image {
					source_url
					alt_text
				  }
				}
			}
		}
	`);

	const visible = posts.slice(0, 3 + (3 * page));

	return(
		<Outer>
			<ArticleCards
				posts={visible}
			/>

			{ posts.length > visible.length ? (
				<FadeEntry
					className={'load-more'}
				>
					<Button
						onClick={() => setPage(page + 1)}
					>Load more</Button>
				</FadeEntry>
			) : null }
		</Outer>
	);
}

const BigOne = styled(ArticleCard)`
	width: 50%;
	
	.article-cards__card {
		&__info {
			padding-top: 20px;
			
			${Title} {
				margin-top: 0;
				padding-right: 55px;
				font-size: ${({ theme }) => theme.fontSize.titleMd};
			}
		}
		
		&__category {
			width: 52px;
			height: 50px;
		}
	}
	
	.article-cards__card__img {
		padding-bottom: 60%;
	}
	
	.read-more {
		margin-top: 30px;
	}
`;

const FirstRowRightPosts = styled.div`
	display: flex;
	justify-content: space-between;
	
	${ArticleCard} {
		width: calc(50% - 15px);
		margin-bottom: 40px;
		
		&:nth-of-type(3n+1),
		&:nth-of-type(3n+2) {
			margin-right: 0;
		}
		
		&:nth-of-type(2n+1) {
			margin-right: 30px;
		}
	}
`;

const FirstRowRight = styled.div`
	width: 50%;
`;

const FirstRow = styled.div`
	display: flex;
	justify-content: space-between;
	
	+* {
		margin-top: 60px;
	}
	
	@media (max-width: 899px) {
		flex-wrap: wrap;
	}
`;

const Outer = styled.div`
	.load-more {
		text-align: center;
		margin-top: 60px;
	}
	
	@media (max-width: 899px) {
		${BigOne} {
			width: 100%;
			margin: 0;
			
			.article-cards__card__img {
				padding-bottom: 72%;
			}
		}
		
		${FirstRowRight} {
			width: 100%;
			margin-top: 50px;
		}

		${FirstRowRightPosts} {
			flex-wrap: wrap;
		}
	}
	
	@media (max-width: 599px) {
		.load-more {
			margin-top: 50px;
		}
		
		${BigOne} {
			.article-cards__card {
				&__category {
					width: 44px;
					height: 42px;
				}
			}
		}
		
		${FirstRowRight} {
			${ArticleCard} {
				width: 100%;
				
				&:nth-of-type(2n+1) {
					margin: 0;
				}
			}
		}
	}
`;

export default ArticleCardsBigOne;