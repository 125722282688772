import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { format } from 'date-fns';
import styled from 'styled-components';
import memoize from 'memoize-one';

import FadeEntry from './FadeEntry';
import Title from './Title';

const ArticleCards = ({ posts }) => {
	let { wordpressAcfOptions: {
		options: {
			placeholder_image
		}
	} } = useStaticQuery(graphql`
		{
			wordpressAcfOptions {
				options {
				  placeholder_image {
					source_url
					alt_text
				  }
				}
			}
		}
	`);

	return(
		<Outer>
			{ posts.map(({ node }) => {
				let img = node && node.acf.post_image && node.acf.post_image.source_url ? node.acf.post_image : placeholder_image;

				return(
					<ArticleCard key={`post-${node.slug}`}>
						<FadeEntry
							className={'article-cards__card'}
							key={`article-${node.slug}`}
						>
							<div className="article-cards__card__img">
								{ node.acf.external_link ? (
									<a href={node.acf.external_link} target={"_blank"}>
										<img src={img.source_url} alt={img.alt_text} />
									</a>
								) : (
									<Link to={`/news/${node.slug}`}>
										<img src={img.source_url} alt={img.alt_text} />
									</Link>
								) }
							</div>

							<div className="article-cards__card__info">
								<div className="article-cards__card__category">
									<img
										src={node.acf.external_link ? '/icons/news.svg' : '/icons/original.svg'}
										alt={node.acf.external_link ? 'External article' : 'Original article'}
									/>
								</div>

								<time>{ format(node.date, 'D MMMM YYYY') }</time>
								<Title>
									{ node.acf.external_link ? (
										<a
											href={node.acf.external_link}
											target={'_blank'}
											dangerouslySetInnerHTML={{ __html: node.title }}
										/>
									) : (
										<Link
											to={`/news/${node.slug}`}
											dangerouslySetInnerHTML={{ __html: node.title }}
										/>
									) }
								</Title>

								<div
									className={'article-cards__card__excerpt'}
									dangerouslySetInnerHTML={{ __html: shortenExcerpt(node.excerpt) }}
								/>
							</div>
						</FadeEntry>
					</ArticleCard>
				);
			}) }
		</Outer>
	);
}

const _shortenExcerpt = (excerpt, length = 130) => {
	if(!excerpt) return '';
	return excerpt.slice(0, length) + '...';
}

export const shortenExcerpt = memoize(_shortenExcerpt);

const Outer = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const ArticleCard = styled.div`
	width: calc((100% / 3) - 20px);
	
	.article-cards__card {
		text-align: left;
		
		time {
			display: block;
			font-family: ${({ theme }) => theme.fontFamily.sansHeavy};
			color: ${({ theme }) => theme.color.blueAlt};
		}
		
		${Title} {
			margin: 15px 0;
			font-family: ${({ theme }) => theme.fontFamily.sansMedium};
			font-size: ${({ theme }) => theme.fontSize.title};
		}
		
		a {
			text-decoration: none;
			color: ${({ theme }) => theme.color.text};
		}
		
		&__img {
			position: relative;
			height: 0;
			padding-bottom: 60%;
			
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;

				&[src*="British_IP-day"] {
					object-fit: contain;
				}
			}
			
			a {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				
				&:before {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					background: rgba(255, 255, 255, 0.25);
					opacity: 0;
					transition: opacity .3s;
					z-index: 2;
				}
				
				&:hover:before {
					opacity: 1;
				}
			}
		}
		
		&__category {
			position: absolute;
			top: 0;
			right: 0;
			width: 44px;
			height: 42px;
			
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		
		&__info {
			position: relative;
			padding-top: 23px;
		}
	}
	
	&:nth-of-type(3n+1),
	&:nth-of-type(3n+2) {
		margin-right: 30px;
	}
	
	&:nth-of-type(n+4) {
		margin-top: 50px;
	}
	
	@media (max-width: 991px) {
		width: calc(50% - 15px);
	
		&:nth-of-type(3n+1),
		&:nth-of-type(3n+2) {
			margin-right: 0;
		}
		&:nth-of-type(n+4) {
			margin-top: 0;
		}
		
		&:nth-of-type(2n+1) {
			margin-right: 30px;
		}
		
		&:nth-of-type(n+3) {
			margin-top: 50px;
		}
	}
	
	@media (max-width: 599px) {
		width: 100%;
	
		&:nth-of-type(2n+1) {
			margin-right: 0;
		}
		&:nth-of-type(n+3) {
			margin-top: 0;
		}
		
		&:nth-of-type(n+2) {
			margin-top: 50px;
		}
	}
`;

export default ArticleCards;