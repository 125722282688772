import React from 'react';
import Slider from 'react-slick';
import memoize from 'memoize-one';
import styled from 'styled-components';
import { format } from 'date-fns';
import '../../node_modules/slick-carousel/slick/slick.css';

import FadeEntry from './FadeEntry';

const sliderSettings = {
	slidesToShow: 1,
	dots: true,
	arrows: false,
	infinite: false,
	speed: 500,
	fade: true,
	cssEase: 'linear',
	adaptiveHeight: true
}

const Tweets = ({ tweets, single, slider }) => {

	if(!tweets || !tweets.length) return null;

	if(slider) {
		return(
			<Outer
				single={single}
			>
				<Slider {...sliderSettings}>
					{ tweets.map(tweet => (
						<FadeEntry
							className={'tweet'}
							key={`tweet-${tweet.created_at}`}
						>
							<div className="tweet__meta">
								<strong>
									<a href={`https://twitter.com/${tweet.user.screen_name}`} target={"_blank"}>{ tweet.user.name }</a>
								</strong>
								<span>
									<a href={`https://twitter.com/${tweet.user.screen_name}`} target={"_blank"}>@{ tweet.user.screen_name }</a>
								</span>
								<time>{ format(tweet.created_at, 'MMMM Do') }</time>
							</div>

							<div className="tweet__tweet">
								<p dangerouslySetInnerHTML={{ __html: makeTweetText(tweet) }} />
							</div>
						</FadeEntry>
					)) }
				</Slider>
			</Outer>
		);
	}

	return(
		<Outer
			single={single}
		>
			{ tweets.map(tweet => (
				<FadeEntry
					className={'tweet'}
					key={`tweet-${tweet.created_at}`}
				>
					<div className="tweet__meta">
						<strong>{ tweet.user.name }</strong>
						<span>
							<a href={`https://twitter.com/${tweet.user.screen_name}`}>@{ tweet.user.screen_name }</a>
						</span>
						<time>{ format(tweet.created_at, 'MMMM Do') }</time>
					</div>

					<div className="tweet__tweet">
						<p dangerouslySetInnerHTML={{ __html: makeTweetText(tweet) }} />
					</div>
				</FadeEntry>
			)) }
		</Outer>
	);
}

const _makeTweetText = ({ entities, text }) => {
	let tweet = text;

	if(entities.urls.length) {
		entities.urls.forEach(({ url, expanded_url }) => {
			tweet = tweet.replace(url, `<a href="${expanded_url}" target="_blank">${url}</a>`);
		});
	}

	if(entities.user_mentions.length) {
		entities.user_mentions.forEach(({ screen_name }) => {
			tweet = tweet.replace(screen_name, `<a href="https://twitter.com/${screen_name}" target="_blank">${screen_name}</a>`);
		});
	}

	return tweet;
}

const makeTweetText = memoize(_makeTweetText);

export const Outer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	
	.slick-slider {
		width: 100%;
	}
	
	.slick-list {
		overflow: visible;
	}
	
	.slick-dots {
		margin-top: 15px;
		text-align: center;
	
		li {
			display: inline-block;
			
			+li {
				margin-left: 6px;
			}
			
			&.slick-active {
				button {
					background: ${({ theme }) => theme.color.brand};
				}
			}
		}
		
		button {
			width: 0;
			height: 0;
			padding: 5px;
			border: 0;
			border-radius: 50%;
			text-indent: -99999px;
			font-size: 0;
			background: ${({ theme }) => theme.color.greyDark};
		}
	}

	.tweet {
		width: ${({ single }) => single ? '100%' : 'calc(50% - 14px)'};
		padding: 32px 24px;
		box-shadow: ${({ theme }) => theme.shadow.card};
		border-radius: ${({ theme }) => theme.radius.tweetCard};
		text-align: left;
		background-image: url('/images/twitter-logo.svg');
		background-size: 43px;
		background-repeat: no-repeat;
		background-position: top right;
		
		* {
			color: ${({ theme }) => theme.color.text};
		}
		
		p, time, span {
			opacity: .8;
		}
		
		a {
			text-decoration: none;
		}
		
		&__meta {
			font-family: ${({ theme }) => theme.fontFamily.sansLight};
			font-size: ${({ theme }) => theme.fontSize.bodyLg};
			
			strong {
				font-weight: 500;
				font-family: ${({ theme }) => theme.fontFamily.sansMedium};
			}
			
			>* {
				+* {
					padding-left: 24px;
				}
				
				+time {
					position: relative;
					
					&:before {
						content: '';
						position: absolute;
						top: 50%;
						left: 10px;
						transform: translateY(-50%);
						width: 3px;
						height: 3px;
						border-radius: 50%;
						background: ${({ theme }) => theme.color.text};
					}
				}
			}
		}
		
		&__tweet {
			margin-top: 15px;
			
			a {
				color: ${({ theme }) => theme.color.link};
			}
		}
		
		@media (max-width: 991px) {
			&__meta {
				>* {
					display: block;
					
					+* {
						padding: 5px 0 0 0;
						font-size: ${({ theme }) => theme.fontSize.bodySm};
					}
					
					+time:before {
						display: none;
					}
				}
				
				strong {
					font-size: ${({ theme }) => theme.fontSize.bodyMd};
				}
			}
		}
		
		@media (max-width: 599px) {
			width: 100%;
			
			&:nth-of-type(n+2) {
				margin-top: 28px;
			}
		}
	}
	
	+* {
		margin-top: 50px;
	}
`;

export default Tweets;